.loading {
    color: white;
    text-align: center;
    padding-top: 304px;
    font-weight: bolder;
    font-size: 26px;
}

.goldicnslid {
    margin: 17vw auto 0 !important;
}

.planrightsec {
    margin-top: 35px;
    z-index: 1 !important;
}

.cpbtnsb .goldbtn,
.goldbgmain .goldbtn {
    background: linear-gradient(90deg, #FFAD12 0%, #EF5F24 100%) !important;
    cursor: pointer;
}

.cpbtnsb .disablegoldbtn,
.goldbgmain .disablegoldbtn {
    background: grey !important;
}

img.gifimg {
    left: 50% !important;
    transform: translateX(-50%);
}

.ruppetxt {
    margin-bottom: 8px;
}

.slidstxt {
    color: #fff !important;
}

.seeBenefits {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 12.61px;
    color: #ffad12;
}

.premiumText {
    font-size: 1rem;
    text-align: center;
    color: #fff;
    margin-top: 10px;
}

.termsConditions {
    font-size: 1rem;
    text-align: center;
    color: #FF9A02;
    margin-top: 10px;
    cursor: pointer;
}

.pop-up-box {
    width: 650px !important;
    padding: 30px !important;
}

.pop-heading .price-plan {
    font-size: 1.5rem;
    color: #fff;
    font-weight: bolder;
}

.pop-heading .day-plan {
    font-size: 1rem;
    color: #fff;
    font-weight: 700;
    margin-top: 0.5rem;
}

.pop-heading .pop-up-title {
    font-size: 1rem;
    color: #fff;
    font-weight: 700;
    padding: 1.5rem;
}

.pop-cancel {
    font-size: 1rem;
    margin-top: 1rem;
    cursor: pointer;
}

.pop-plan-benefits {
    margin-top: 1.5rem;
    padding: 0 40px;
}

.pop-plan-benefits img {
    margin: 0.4rem;
}

.subcribe-text {
    font-size: 1.1rem;
    margin-top: 1.5rem;
}

.otp-input-container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.otp-input-box {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #2c2c2c;
    color: #fff;
}

.otp-input-box:focus {
    border-color: #ffa500;
    outline: none;
}

.otp-error-box {
    border-color: red;
}

.error-text {
    color: red;
    text-align: center;
    font-size: 1rem;
    font-weight: bolder;
    margin-top: 1.5rem;
}

.resend-otp-container {
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
}

.resend-otp {
    color: #ffa500;
}

.bxplan .goldbtn {
    cursor: pointer;
}

.right-image,
.wrong-image {
    margin-top: 2rem;
}

.textTitle {
    font-size: 1.5rem;
    font-weight: bolder;
    margin-bottom: 1rem;
}

.bxplan.active-plan {
    background-color: none !important;
}

.bxplan.in-active-plan {
    color: #c6c6c6 !important;
    background-color: #ffffff3b !important;
}

.paypopup-box {
    width: 550px !important;
}

@media (max-width: 768px) {
    .hlogo {
        background: none !important;
    }

    .slidftxt {
        padding: 0 15px;
        text-align: left !important;
        top: 53vw !important;
        font-size: 1.2rem !important;
    }

    .slidstxt {
        padding: 0 15px;
        text-align: left !important;
        font-size: 1rem !important;
    }

    .slidstxt p img {
        width: 25px;
        margin-right: 10px;
    }

    .slidstxt .slidstxt1 {
        margin-bottom: 10px;
    }

    .plans .bxplan {
        height: 25vw !important;
    }

    .see-benefits-pop-up {
        height: 110vw !important;
    }

    .button-blue {
        background: linear-gradient(90deg, #FFAD12 0%, #EF5F24 100%) !important;
    }

    .button-blue img {
        width: 25px;
    }

    .button-blue {
        width: 80% !important;
        margin-top: 1rem;
        display: inline-block;
        padding: 12px 100px !important;
    }

    .see-subscribe-pop-up {
        height: 80vw !important;
    }

    .see-otp-pop-up {
        height: 100vw !important;
    }

    .see-otp-pop-up .resend-otp-container {
        margin-top: 1rem;
    }

    .see-otp-pop-up .button-blue {
        padding: 12px 115px !important;
    }

    .see-congratulation-pop-up,
    .see-payment-failed-pop-up {
        height: 91vw !important;
    }

    .see-congratulation-pop-up .subcribe-text,
    .see-payment-failed-pop-up .subcribe-text {
        margin-bottom: 1.5rem;
    }

    .pop-plan-benefits {
        padding: 0;
    }

    .in-active-plan .bxplan {
        background-color: #ffffff3b !important;
    }

}